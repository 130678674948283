import React, { useEffect, useState } from 'react'
import Navbar from "./Navbar"
import "../components/CSS/style.css"

const Layout = () => {
    const [data, setData] = useState([]);
    console.log(data)

    useEffect(() => {
        const fetchApi = async () => {
            const url = "https://jsonplaceholder.typicode.com/photos"
            const response = await fetch(url);

            const dataJson = await response.json();
            //console.log(dataJson.main);
            setData(dataJson)
        }

        fetchApi();



    }, [])
    return (
        <>
            <Navbar />
            <div className="container">
                {
                    data.slice(0,50).map((elem) => {
                 const {url,title} =elem
                        return <>
                            <div className="cards">
                                <img src={url} alt="img" />

                                <div className="title_class">
                                    <h3>{title}</h3>

                                </div>

                            </div>
                        </>
                    })
                }

            </div>
        </>
    )
}

export default Layout
