import React from 'react'

const Navbar = () => {
  return (
    <>
            <nav className="main-nav">
             
                <div className="logo">
                    <h2>
                   FetchData
                        
                    </h2>
                </div>

                
            </nav>
                      
        </>
  )
}

export default Navbar
