import logo from './logo.svg';
import './App.css';
import Layout from "./components/Layout"

function App() {
  return (
   <>
   <Layout/>
   
   </>
  );
}

export default App;
